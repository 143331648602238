define("ember-data-strepla/urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TASK_URL = _exports.COMPETITOR_LIST_URL = _exports.COMPETITION_DAY_LIST_URL = _exports.COMPETITION_CLASS_LIST_URL = _exports.COMPETITION_LIST_URL = void 0;
  const COMPETITION_LIST_URL = 'https://www.strepla.de/scs/ws/competition.ashx?cmd=active';
  _exports.COMPETITION_LIST_URL = COMPETITION_LIST_URL;
  const COMPETITION_CLASS_LIST_URL = 'https://www.strepla.de/scs/ws/compclass.ashx?cmd=overview';
  _exports.COMPETITION_CLASS_LIST_URL = COMPETITION_CLASS_LIST_URL;
  const COMPETITION_DAY_LIST_URL = 'https://www.strepla.de/scs/ws/results.ashx?cmd=overviewDays';
  _exports.COMPETITION_DAY_LIST_URL = COMPETITION_DAY_LIST_URL;
  const COMPETITOR_LIST_URL = 'https://www.strepla.de/scs/ws/pilot.ashx?cmd=competitors';
  _exports.COMPETITOR_LIST_URL = COMPETITOR_LIST_URL;
  const TASK_URL = 'https://www.strepla.de/scs/ws/results.ashx?cmd=task';
  _exports.TASK_URL = TASK_URL;
});