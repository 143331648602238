define("ember-css-modules/mixins/component-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    localClassNames: null,
    localClassNameBindings: null,
    concatenatedProperties: ['localClassNames', 'localClassNameBindings'],

    init() {
      this._super();

      if (this.tagName === '') return;
      this.classNameBindings = [...this.classNameBindings, ...localClassNames(this), LOCAL_CLASS_NAMES_CP];

      if (this.localClassNameBindings.length) {
        let value = localClassNamesCP(this.localClassNameBindings, this.get('__styles__'));
        Ember.defineProperty(this, LOCAL_CLASS_NAMES_CP, value);
      }
    },

    __styles__: Ember.computed(function () {
      let key = this._debugContainerKey;

      if (!key) {
        return;
      }

      let name = key.replace(/^component:/, '');
      let layout = this.layout || Ember.getOwner(this).lookup(`template:components/${name}`);
      (false && !(layout) && Ember.assert(`Unable to resolve localClassNames or localClassNameBindings for component ${name}, which has no ` + `layout. You can fix this by either creating an empty template for your component or importing and ` + `using the styles hash directly instead, e.g. \`classNames: styles['my-class']\`.`, layout)); // Since https://github.com/emberjs/ember.js/pull/18096

      if (typeof layout === 'function') layout = layout(Ember.getOwner(this)); // This is not public API and might break at any time...

      let moduleName = (layout.meta || layout.referrer).moduleName.replace(/\.hbs$/, '');

      if (/\/template$/.test(moduleName)) {
        return tryLoad(moduleName.replace(/template$/, 'styles'));
      } else if (/\/templates\//.test(moduleName)) {
        return tryLoad(moduleName.replace('/templates/', '/styles/'));
      }
    })
  });

  _exports.default = _default;

  function tryLoad(path) {
    if (require.has(path)) {
      return require(path).default;
    }
  }

  const LOCAL_CLASS_NAMES_CP = '__ecm_local_class_names__';

  function localClassNames(component) {
    return component.localClassNames.map(className => `__styles__.${className}`);
  }

  function localClassNamesCP(localClassNameBindings, styles) {
    let bindings = localClassNameBindings.map(binding => {
      let [property, trueStyle, falseStyle] = binding.split(':');
      let trueClasses = styles[trueStyle || Ember.String.dasherize(property)] || '';
      let falseClasses = styles[falseStyle] || '';
      let isBoolean = !!trueStyle;
      return {
        property,
        trueClasses,
        falseClasses,
        isBoolean
      };
    });
    return Ember.computed(...bindings.map(binding => binding.property), function () {
      let classes = [];
      bindings.forEach(binding => {
        let value = this.get(binding.property);

        if (binding.isBoolean || typeof value !== 'string') {
          classes.push(value ? binding.trueClasses : binding.falseClasses);
        } else {
          classes.push(value.split(/\s+/).map(key => styles[key]).join(' '));
        }
      });
      return classes.join(' ');
    });
  }
});